export class NavigationData_1__1
{
    constructor(data)
    {
        this.store = data

        this.c1 = document.querySelector('[data-s~="caller_1__1"]')
        this.c2 = document.querySelector('[data-s~="caller_1__2"]')
        this.c3 = document.querySelector('[data-s~="caller_1__3"]')
        this.c4 = document.querySelector('[data-s~="caller_1__4"]')

        this.t1 = document.querySelector('[data-s~="callerText_1__1"]')
        this.t2 = document.querySelector('[data-s~="callerText_1__2"]')
        this.t3 = document.querySelector('[data-s~="callerText_1__3"]')
        this.t4 = document.querySelector('[data-s~="callerText_1__4"]')

        this.s1 = document.querySelector('[data-s~="switcher_1__1"]')
        this.s2 = document.querySelector('[data-s~="switcher_1__2"]')
        this.s3 = document.querySelector('[data-s~="switcher_1__3"]')
        this.s4 = document.querySelector('[data-s~="switcher_1__4"]')
    }

    run()
    {
        this.reset()
        this.switch_1()
    }

    reset()
    {
        this.c1.style.backgroundColor = null
        this.c2.style.backgroundColor = null
        this.c3.style.backgroundColor = null
        this.c4.style.backgroundColor = null

        this.t1.style.color = null
        this.t2.style.color = null
        this.t3.style.color = null
        this.t4.style.color = null

        this.s1.style.display = null
        this.s2.style.display = null
        this.s3.style.display = null
        this.s4.style.display = null
    }

    switch_1()
    {
        this.c1.style.backgroundColor = (contrastVersion) ? "#333333" : "#b50315"
        this.t1.style.color = (contrastVersion) ? "yellow" : "white"
        this.s1.style.display = "flex"
    }

    switch_2()
    {
        this.c2.style.backgroundColor = (contrastVersion) ? "#333333" : "#b50315"
        this.t2.style.color = (contrastVersion) ? "yellow" : "white"
        this.s2.style.display = "flex"
    }

    switch_3()
    {
        this.c3.style.backgroundColor = (contrastVersion) ? "#333333" : "#b50315"
        this.t3.style.color = (contrastVersion) ? "yellow" : "white"
        this.s3.style.display = "flex"
    }

    switch_4()
    {
        this.c4.style.backgroundColor = (contrastVersion) ? "#333333" : "#b50315"
        this.t4.style.color = (contrastVersion) ? "yellow" : "white"
        this.s4.style.display = "flex"
    }

    watch()
    {
        this.c1.onclick = () => {
            this.reset()
            this.switch_1()
        }
        this.c2.onclick = () => {
            this.reset()
            this.switch_2()
        }
        this.c3.onclick = () => {
            this.reset()
            this.switch_3()
        }
        this.c4.onclick = () => {
            this.reset()
            this.switch_4()
        }
    }
}
