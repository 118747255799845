export class Languages
{
    config = {
        "default_language": "pl",
        "languages": [
            "pl",
            "uk",
            "en",
            "de",
        ],
        "wrapper_selector": ".gtranslate_wrapper",
        "flag_size": 20,
    }

    run()
    {
        window.gtranslateSettings = this.config
        let script = document.createElement("script")
        script.src = "https://cdn.gtranslate.net/widgets/latest/flags.js"
        script.defer = true
        document.body.appendChild(script)
    }
}
